export default {
    v: '5.7.4',
    fr: 60,
    ip: 0,
    op: 240,
    w: 1920,
    h: 210,
    nm: 'Lines no ziga-zag',
    ddd: 0,
    assets: [],
    layers: [
        {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: 'Layer 1 Outlines 2',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                    a: 1,
                    k: [
                        {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            t: 0,
                            s: [2640, 106, 0],
                            to: [-97.833, 0, 0],
                            ti: [97.833, 0, 0],
                        },
                        { t: 240, s: [2053, 106, 0] },
                    ],
                    ix: 2,
                    l: 2,
                },
                a: { a: 0, k: [3232.025, 102.5, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ty: 'st',
                            c: { a: 0, k: [0.945098039216, 0.654901960784, 0.23137254902, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 1, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Shape 1',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-146.8, 0],
                                        [-146.8, 0],
                                        [-146.799, 0],
                                        [-146.797, 0],
                                        [-146.795, 0],
                                        [-146.797, 0],
                                        [-146.798, 0],
                                        [-146.797, 0],
                                        [-146.797, 0],
                                        [-146.795, 0],
                                        [-146.796, 0],
                                        [3.228, 0.045],
                                        [-111.055, 0],
                                    ],
                                    o: [
                                        [146.8, 0],
                                        [146.801, 0],
                                        [146.8, 0],
                                        [146.797, 0],
                                        [146.795, 0],
                                        [146.796, 0],
                                        [146.797, 0],
                                        [146.796, 0],
                                        [146.797, 0],
                                        [146.795, 0],
                                        [146.795, 0],
                                        [35.741, 0],
                                        [-10.03, -0.139],
                                        [146.796, 0],
                                    ],
                                    v: [
                                        [-3229.525, 100],
                                        [-2935.925, -100],
                                        [-2642.324, 100],
                                        [-2348.725, -100],
                                        [-2055.131, 100],
                                        [-1761.541, -100],
                                        [-1467.948, 100],
                                        [-1174.353, -100],
                                        [-880.76, 100],
                                        [-587.167, -100],
                                        [-293.576, 100],
                                        [0.015, -100],
                                        [42.513, -100.074],
                                        [5.933, -100.5],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0.945098042488, 0.654901981354, 0.23137255013, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 0.5, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            d: [
                                { n: 'd', nm: 'dash', v: { a: 0, k: 2, ix: 1 } },
                                { n: 'g', nm: 'gap', v: { a: 0, k: 4, ix: 2 } },
                                {
                                    n: 'o',
                                    nm: 'offset',
                                    v: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: [0.833], y: [0.833] },
                                                o: { x: [0.167], y: [0.167] },
                                                t: 0,
                                                s: [0],
                                            },
                                            {
                                                i: { x: [0.833], y: [0.833] },
                                                o: { x: [0.167], y: [0.167] },
                                                t: 239,
                                                s: [228.58],
                                            },
                                            {
                                                i: { x: [0.833], y: [0.833] },
                                                o: { x: [0.167], y: [0.167] },
                                                t: 240,
                                                s: [201.42],
                                            },
                                            {
                                                i: { x: [0.833], y: [0.833] },
                                                o: { x: [0.167], y: [0.167] },
                                                t: 479,
                                                s: [402],
                                            },
                                            { t: 480, s: [903] },
                                        ],
                                        ix: 7,
                                        x: 'var $bm_rt;\n$bm_rt = loopOut();',
                                    },
                                },
                            ],
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [3232.025, 102.5], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 1',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 240,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: 'Layer 1 Outlines 3',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                    a: 1,
                    k: [
                        {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            t: 0,
                            s: [2935, 105, 0],
                            to: [-97.833, 0, 0],
                            ti: [97.833, 0, 0],
                        },
                        { t: 240, s: [2348, 105, 0] },
                    ],
                    ix: 2,
                    l: 2,
                },
                a: { a: 0, k: [3232.025, 102.5, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-146.8, 0],
                                        [-146.8, 0],
                                        [-146.799, 0],
                                        [-146.797, 0],
                                        [-146.795, 0],
                                        [-146.797, 0],
                                        [-146.798, 0],
                                        [-146.797, 0],
                                        [-146.797, 0],
                                        [-146.795, 0],
                                    ],
                                    o: [
                                        [146.8, 0],
                                        [146.801, 0],
                                        [146.8, 0],
                                        [146.797, 0],
                                        [146.795, 0],
                                        [146.796, 0],
                                        [146.798, 0],
                                        [146.796, 0],
                                        [146.797, 0],
                                        [146.795, 0],
                                        [146.795, 0],
                                    ],
                                    v: [
                                        [-3229.525, 100],
                                        [-2935.925, -100],
                                        [-2642.324, 100],
                                        [-2348.725, -100],
                                        [-2055.131, 100],
                                        [-1761.541, -100],
                                        [-1467.948, 100],
                                        [-1174.353, -100],
                                        [-880.76, 100],
                                        [-587.166, -100],
                                        [-293.576, 100],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0.945098042488, 0.654901981354, 0.23137255013, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 1, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            d: [
                                { n: 'd', nm: 'dash', v: { a: 0, k: 28, ix: 1 } },
                                { n: 'g', nm: 'gap', v: { a: 0, k: 10, ix: 2 } },
                                {
                                    n: 'o',
                                    nm: 'offset',
                                    v: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: [0.833], y: [0.833] },
                                                o: { x: [0.167], y: [0.167] },
                                                t: 0,
                                                s: [0],
                                            },
                                            {
                                                i: { x: [0.833], y: [0.833] },
                                                o: { x: [0.167], y: [0.167] },
                                                t: 240,
                                                s: [478],
                                            },
                                            { t: 479, s: [478] },
                                        ],
                                        ix: 7,
                                        x: 'var $bm_rt;\n$bm_rt = loopOut();',
                                    },
                                },
                            ],
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [3232.025, 102.5], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 1',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 240,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 3,
            ty: 4,
            nm: 'Layer 1 Outlines',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                    a: 1,
                    k: [
                        {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            t: 0,
                            s: [3078, 105, 0],
                            to: [-95.88, 0, 0],
                            ti: [100.523, 0, 0],
                        },
                        { t: 240, s: [2491, 105, 0] },
                    ],
                    ix: 2,
                    l: 2,
                },
                a: { a: 0, k: [3232.025, 102.5, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [146.795, 0],
                                        [146.795, 0],
                                        [146.797, 0],
                                        [146.796, 0],
                                        [146.797, 0],
                                        [146.796, 0],
                                        [146.795, 0],
                                        [146.797, 0],
                                        [146.8, 0],
                                        [146.801, 0],
                                        [146.8, 0],
                                    ],
                                    o: [
                                        [-146.795, 0],
                                        [-146.797, 0],
                                        [-146.797, 0],
                                        [-146.798, 0],
                                        [-146.797, 0],
                                        [-146.795, 0],
                                        [-146.797, 0],
                                        [-146.799, 0],
                                        [-146.8, 0],
                                        [-146.8, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-293.576, 100],
                                        [-587.166, -100],
                                        [-880.76, 100],
                                        [-1174.353, -100],
                                        [-1467.948, 100],
                                        [-1761.541, -100],
                                        [-2055.131, 100],
                                        [-2348.725, -100],
                                        [-2642.324, 100],
                                        [-2935.925, -100],
                                        [-3229.525, 100],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0.989018937653, 0.67137187883, 0.183887870639, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 1, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 10,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [3232.025, 102.5], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 1',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 240,
            st: 0,
            bm: 0,
        },
    ],
    markers: [],
};
