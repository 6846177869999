function init(container = document) {
    const burgerLinks = Array.from(container.querySelectorAll('.js-mobile-header-menu-link'));
    const burgerMenuContainer = container.querySelector('.js-mobile-menu');

    burgerLinks.forEach(link => {
        const newLink = link.cloneNode(true) as HTMLElement;
        newLink.classList.remove('header__link');
        newLink.classList.remove('text-xs');
        newLink.classList.add('mobile-menu__link');
        const li = document.createElement('li');
        li.classList.add('mobile-menu__li');
        li.appendChild(newLink);
        burgerMenuContainer?.appendChild(li);
    })
}

export default { init };