export const fetchLodashThrottle = async () =>
    import('lodash.throttle' /* webpackChunkName: "lodash.throttle" */).then((m) => m.default);
export const fetchIMask = async () => import('imask' /* webpackChunkName: "imask" */).then((m) => m.default);
export const fetchContentList = () =>
    import(/* webpackChunkName: "content-list" */ '@chipsadesign/content-list').then((m) => m.default);

export const fetchContentListFilter = () =>
    import(
        /* webpackChunkName: "content-list-filter" */ '@chipsadesign/content-list/dist/modules/ContentListFilter'
    ).then((m) => m.default);

export const fetchContentListSorting = () =>
    import(
        /* webpackChunkName: "content-list-sorting" */ '@chipsadesign/content-list/dist/modules/ContentListSorting'
    ).then((m) => m.default);

export const fetchSlimSelect = async () => import(/* webpackChunkName: "slim-select" */ 'slim-select');

export const fetchLightgallery = () =>
    Promise.all([
        import(
            'lightgallery'
            /* webpackChunkName: "lightgallery" */
        ),
        // import(
        //     'lightgallery/css/lightgallery.css'
        //     /* webpackChunkName: "lightgallery-styles" */
        // ),
        // import(
        //     '../css/layout/_lightgallery.scss'
        //     /* webpackChunkName: "lightgallery-app-styles" */
        // ),
    ]).then(([m]) => m);
