import Swiper, { Navigation, Pagination, Autoplay, A11y, Lazy } from 'swiper';

Swiper.use([Navigation, Pagination, Autoplay, A11y, Lazy]);

const map = new WeakMap<Element, Swiper>();
const intersectionsMap = new WeakMap<Element, IntersectionObserver>();

let timer: NodeJS.Timeout;

const callback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
        clearTimeout(timer);
        if (entry.isIntersecting) {
            const slider = map.get(entry.target);
            if (slider) {
                slider.autoplay.start();
                entry.target.classList.add('slider-autoplay');
            }
        } else {
            const slider = map.get(entry.target);
            if (slider) {
                slider.autoplay.stop();
                entry.target.classList.add('slider-autoplay');
            }
        }
    });
};

const commonRootMargin = '0px 0px 0px 0px';
const commonObserver = new IntersectionObserver(callback, { rootMargin: commonRootMargin, threshold: [0, 1] });

function init(container: HTMLElement | Document = document) {
    const sliderEl = container.querySelector<HTMLElement>('.js-slider-index');
    if (sliderEl) {
        const slides = Array.from(sliderEl.querySelectorAll('.js-index-slider-slide'));
        const slidesLength = slides.length;
        const totalSlidesEl = sliderEl.querySelector('.js-total-slides');
        const currentSlideEl = sliderEl.querySelector('.js-current-slide');
        const slideTitleEl = sliderEl.querySelector('.js-index-slide-title');
        const slider = new Swiper(sliderEl, {
            navigation: {
                nextEl: '.js-index-slider-button-next',
                prevEl: '.js-index-slider-button-prev',
            },
            pagination: {
                el: '.js-index-slider-bullets',
                type: 'bullets',
                // dynamicBullets: true,
                clickable: true,
                bulletClass: 'index-slider-progress',
                bulletActiveClass: 'bullet-active',
            },
            a11y: {
                prevSlideMessage: 'Предыдущий слайд',
                nextSlideMessage: 'Следующий слайд',
            },
            loop: true,
            autoplay: {
                delay: 10000,
                disableOnInteraction: false,
            },
            lazy: {
                loadPrevNext: true,
            },
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },
            init: false,
        });

        map.set(sliderEl, slider);
        const observer = commonObserver;
        observer.observe(sliderEl);
        intersectionsMap.set(sliderEl, observer);

        slider.on('beforeInit', () => {
            if (totalSlidesEl) {
                totalSlidesEl.innerHTML = slidesLength.toString();
            }
        });

        slider.on('slideChange', (instance) => {
            if (currentSlideEl) {
                currentSlideEl.innerHTML = (instance.realIndex + 1).toString();
            }
            const activeSlide = instance.slides[instance.activeIndex] as HTMLElement;
            if (slideTitleEl && activeSlide.dataset.title) {
                slideTitleEl.innerHTML = activeSlide.dataset.title;
            }
        });
        slider.init();
        slider.autoplay.stop();
        timer = setTimeout(() => {
            slider.autoplay.start();
            sliderEl.classList.add('slider-autoplay');
        }, 3000);
    }
}

function destroy(container: HTMLElement | Document = document) {
    const sliderEl = container.querySelector<HTMLElement>('.js-slider-index');
    if (sliderEl) {
        const slider = map.get(sliderEl);
        if (slider) {
            slider.destroy();
            map.delete(sliderEl);
        }
        const observer = intersectionsMap.get(sliderEl);
        if (observer) {
            observer.unobserve(sliderEl);
            intersectionsMap.delete(sliderEl);
        }
    }
}

const _module = { init, destroy };

export default _module;
