
import barba from '@barba/core';

function init() {
    const searchForms = document.querySelectorAll<HTMLFormElement>('.js-search-form');
    searchForms.forEach(form => {
        form.addEventListener('submit', (e: Event) => {
            e.preventDefault();
            const formData = new FormData(form);
            const formAction = form.getAttribute('action');
            const queryValue = formData.get('q');
            if (formAction && queryValue) {
                const endpoint = `${formAction}?q=${queryValue}`;
                barba.go(endpoint)
            }
        })
    })
}

export default { init };