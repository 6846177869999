import { IViewData } from '@barba/core';
import indexSlider from '../inits/index-slider';
import videos from '../inits/videos';
import animateCounters from '../inits/animate-counters';
import indexMap from '../inits/index-map';
import { ease } from '../ease';
import gsap from 'gsap';
import autoplayVideo from '../inits/autoplay-video';

let tl: gsap.core.Timeline | null;

export default {
    namespace: 'index-page',

    beforeEnter({ next }: IViewData) {
        tl = gsap.timeline({ defaults: { duration: 0.8, ease } });
        const marqueeEl = next.container.querySelector('.a-index-marquee');
        const title = next.container.querySelector('.a-index-title');
        const smallCards = Array.from(next.container.querySelectorAll('.a-index-small-card'));
        const sliderWrapper = next.container.querySelector('.a-index-slider-wrapper');
        const slider = next.container.querySelector('.a-index-slider');
        const sliderNav = next.container.querySelector('.a-index-slider-navigation');
        const indexMarqueeWrapper = next.container.querySelector('.js-wrapper-index-sinus-wrapper');

        tl.from(marqueeEl, {opacity: 0, duration: 0.8 }, 0.6)
            .from(title, { y: 100, opacity: 0, duration: 0.8 }, 1.5)
            .from(sliderWrapper, { y: '100%', duration: 0.8 }, 1.5)
            .from(smallCards.reverse(), { y: '200%', duration: 0.6, stagger: 0.3 }, 1.8)
            .from(slider, { y: '-100%', duration: 0.8, onComplete: () => {
                indexMarqueeWrapper?.classList.add('is-narrow');
            }}, 1.5)
            .call(() => slider?.classList.add('is-in'), null, 1.5)
            .from(sliderNav, { y: 100, opacity: 0, duration: 0.8 }, 1.5);

        indexSlider.init(next.container);
        videos.init(next.container);
        animateCounters.init(next.container);
        indexMap.init(next.container);
        autoplayVideo.init(next.container);
    },

    afterEnter() {
        tl?.play();
    },

    afterLeave({ current }: IViewData) {
        if (tl) {
            tl.kill();
            tl = null;
        }
        animateCounters.destroy(current.container);
        indexSlider.destroy(current.container);
        autoplayVideo.destroy(current.container);
        videos.destroy(current.container);
    },
};
