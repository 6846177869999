import axios from 'axios';
import gsap from 'gsap';
import { triggerCustomEvent } from '../utils/trigger-events';
import scrollLock from 'scroll-lock';
import timeout from '../utils/timeout';
import { CustomEase } from 'gsap/CustomEase';

function init() {
    const header = document.querySelector('.js-header');

    const tl = gsap.timeline({ repeat: -1, repeatDelay: 0.5, defaults: { duration: 2, ease } });

    function createPreloader() {
        const preloader = document.querySelector('.js-preloader');
        let wasBodyLocked = false;
        const assets: number[] = [];
        scrollLock.disablePageScroll();

        function enter() {
            if (preloader) {
                wasBodyLocked = document.body.classList.contains('no-scroll');
                if (!wasBodyLocked) {
                    scrollLock.enablePageScroll();
                }
            }
        }

        async function leave() {
            if (preloader) {
                triggerCustomEvent(document, 'preloader.complete');
                await timeout(100);
                preloader.classList.add('preloader--complete');
                setTimeout(() => {
                    preloader.classList.add('preloader--hidden');
                    tl.kill();
                }, 900);
                if (!wasBodyLocked) {
                    scrollLock.enablePageScroll();
                }
            }
        }

        async function loadAsset(img: HTMLImageElement, index: number) {
            try {
                await axios.get(img.currentSrc, {
                    onDownloadProgress: (progressEvent) => {
                        if (img.classList.contains('js-hide-before-load')) {
                            img.classList.add('is-show');
                        }
                        let percent = 0;
                        const _percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        assets[index] = _percentCompleted;

                        for (let i = 0; i < assets.length; i++) {
                            percent += assets[i];
                        }
                    },
                });
            } catch (err) {
                assets[index] = 100;
            }
        }

        async function loadAssetsFromElement(element: Element | Document = document) {
            const images = Array.from(
                element.querySelectorAll<HTMLImageElement>('img:not(.lazy):not([loading="lazy"])'),
            );
            triggerCustomEvent(document, 'preloader.begin');
            return Promise.all(images.map((img, i) => loadAsset(img, i)));
        }

        return { enter, leave, loadAssetsFromElement } as const;
    }
    const ease = CustomEase.create('custom', '.61, -.02 , 0.49, 1');
    const preloader = createPreloader();
    gsap.set(header, { yPercent: -100, opacity: 0 });
    const preloaderCtrl = document.querySelector('.page-preloader__ctrl');
    tl.set(preloaderCtrl, { transformOrigin: 'right' })
        .to(preloaderCtrl, { scaleX: 0 })
        .set(preloaderCtrl, { transformOrigin: 'left' })
        .to(preloaderCtrl, { scaleX: 1 });

    // Initial load
    preloader
        .loadAssetsFromElement()
        .then(() => preloader.leave())
        .then(() => {
            gsap.to(header, { yPercent: 0, opacity: 1, duration: 0.8, delay: 0.8 }, 1.5);
        });
}

export default { init };
