function onFocus(this: HTMLInputElement) {
    const label = this.closest('.js-input-focus-wrapper');
    this.classList.add('is-fill');
    this.classList.remove('is-error')
    label?.classList.remove('is-error')
    label?.classList.add('is-fill');
}

function onBlur(this: HTMLInputElement) {
    if (this.value === '') {
        const label = this.closest('.js-input-focus-wrapper');
        this.classList.remove('is-fill');
        label?.classList.remove('is-fill');
    }
}

function onReady(input: HTMLInputElement) {
    if (input.value !== '') {
        const label = input.closest('.js-input-focus-wrapper');
        input?.classList.add('is-fill');
        label?.classList.add('is-fill');
    } else {
        const label = input.closest('.js-input-focus-wrapper');
        input?.classList.remove('is-fill');
        label?.classList.remove('is-fill');
    }
}

function init(container: HTMLElement | Document = document) {
    const inputs = Array.from(container.querySelectorAll<HTMLInputElement>('.js-input-focus'));
    inputs.forEach(input => {
        input.addEventListener('focus', onFocus);
        input.addEventListener('blur', onBlur);
        onReady(input);
    })
}

function destroy(container: HTMLElement | Document = document) {
    const inputs = Array.from(container.querySelectorAll('.js-input-focus'));

    inputs.forEach(input => {
        input.removeEventListener('focus', onFocus);
        input.removeEventListener('blur', onBlur);
    })
}

export default { init, destroy };