function init(container: HTMLElement | Document = document) {
    const videos: HTMLVideoElement[] = Array.from(container.querySelectorAll('video'));
    videos.forEach((video) => {
        if (video.getAttribute('autoplay') === 'true') {
            if (video.readyState === 4) {
                video.play();
            } else {
                video.addEventListener('canplaythrough', () => {
                    video.play();
                });
            }
        }
    });
}

function destroy(container: HTMLElement | Document = document) {
    const videos: HTMLVideoElement[] = Array.from(container.querySelectorAll('video'));
    videos.forEach((video) => {
        video.pause();
    });
}

const _module = { init, destroy };

export default _module;
