import lottieWeb from 'lottie-web';
import type { AnimationItem } from 'lottie-web';
import lottieData from '../animations/lottie';

const map = new WeakMap<Element, AnimationItem>();
const intersectionsMap = new WeakMap<Element, IntersectionObserver>();

const callback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            const lottieAnimation = map.get(entry.target);
            if (lottieAnimation) {
                lottieAnimation.play();
            }
        } else {
            const lottieAnimation = map.get(entry.target);
            if (lottieAnimation) {
                lottieAnimation.pause();
            }
        }
    });
};
const commonRootMargin = '0px 0px 0px 0px';
const commonObserver = new IntersectionObserver(callback, { rootMargin: commonRootMargin, threshold: [0, 1] });

function init(container: HTMLElement | Document = document) {
    const lottieContainer = Array.from(container.querySelectorAll<HTMLElement>('.js-lottie'));
    if (lottieContainer.length > 0) {
        lottieContainer.forEach((lottie) => {
            const observer = commonObserver;
            observer.observe(lottie);
            intersectionsMap.set(lottie, observer);

            const lottieAnimation: AnimationItem = lottieWeb.loadAnimation({
                container: lottie,
                animationData: lottieData,
                loop: true,
                autoplay: lottie.dataset.autoplay === 'true',
                renderer: 'svg',
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                }
            });
            map.set(lottie, lottieAnimation);
        });
    }
}

function destroy(container: HTMLElement | Document = document) {
    const lottieContainer = Array.from(container.querySelectorAll<HTMLElement>('.js-lottie'));
    if (lottieContainer.length > 0) {
        lottieContainer.forEach((lottie) => {
            const lottieAnimation = map.get(lottie);
            if (lottieAnimation) {
                lottieAnimation.destroy();
                map.delete(lottie);
            }
            const observer = intersectionsMap.get(lottie);
            if (observer) {
                observer.unobserve(lottie);
                intersectionsMap.delete(lottie);
            }
        });
    }
}

const _module = { init, destroy };

export default _module;
