function init() {
    const clearSearchBtn = document.querySelector('.js-search-form-clear');
    const form = clearSearchBtn?.closest('form');
    const input = form?.querySelector<HTMLInputElement>('input[name="q"]');

    clearSearchBtn?.addEventListener('click', () => {
        if (input) {
            input.value = ''
        }
    });
}

export default { init };