export default function initFileInput(container: HTMLElement | Document = document) {
    const fileInputs = Array.from(container.querySelectorAll<HTMLInputElement>('.js-file-input'));

    fileInputs.forEach((input) => {
        const wrapper = input.closest<HTMLElement>('.js-file-input-wrapper');

        if (wrapper) {
            const titleEl = wrapper.querySelector('.js-file-input-text');
            const titleDefault = wrapper.dataset.fileInputTitle || '';
            const fileRemoveEl = wrapper.querySelector('.js-file-input-remove');

            input.addEventListener('change', (event) => {
                const currentInput = event.target as HTMLInputElement;

                if (titleEl) {
                    if (currentInput && currentInput.files && currentInput.files.length > 0) {
                        wrapper.classList.add('is-fill');
                        titleEl.innerHTML = currentInput.files[0].name;
                    } else {
                        wrapper.classList.remove('is-fill');
                        titleEl.innerHTML = titleDefault;
                    }
                }
            });

            if (fileRemoveEl) {
                fileRemoveEl.addEventListener('click', () => {
                    input.files = null;
                    input.value = '';
                    input.dispatchEvent(new Event('change'));
                });
            }
        }
    });
}
