const menu = document.querySelector('[data-lit-popup="menu"]');
const menuTrigger = document.querySelector<HTMLElement>('.js-menu-trigger');
const menuTriggerClose = document.querySelector<HTMLElement>('.js-menu-trigger-close');
let menuTriggerCloseWidth;
let menuTriggerPaddingRight;
let menuTriggerWidth;
const header: HTMLElement | null = document.querySelector('.js-header');
let headerHeight = 0;
const body = document.querySelector<HTMLElement>('body');

function calcMenuTriggerWidth() {
    if (menuTrigger && menuTriggerClose) {
        menuTriggerCloseWidth = menuTriggerClose.offsetWidth;
        menuTriggerPaddingRight = parseInt(window.getComputedStyle(menuTrigger).paddingRight, 10);
        menuTriggerWidth = menuTriggerCloseWidth + menuTriggerPaddingRight;
        menuTrigger.style.width = `${menuTriggerWidth}px`;
    }
}

function calcHeaderHeight() {
    headerHeight = header?.offsetHeight || 0;
}

function onOpen() {
    if (header && !body?.classList.contains('white-page') && window.scrollY < headerHeight) {
        header.classList.add('header--white');
    }
    setTimeout(() => {
        if (menuTrigger) {
            menuTrigger.removeAttribute('data-lit-popup-open');
            menuTrigger.setAttribute('data-lit-popup-close', 'menu');
        }
    }, 10);
}

function onClose() {
    if (header && window.scrollY < headerHeight && !body?.classList.contains('white-page')) {
        header.classList.remove('header--white');
    }
    setTimeout(() => {
        if (menuTrigger) {
            menuTrigger.removeAttribute('data-lit-popup-close');
            menuTrigger.setAttribute('data-lit-popup-open', 'menu');
        }
    }, 10);
}

function init() {
    calcHeaderHeight();
    calcMenuTriggerWidth();
    window.addEventListener('resize', calcMenuTriggerWidth);
    window.addEventListener('resize', calcHeaderHeight);
    menu?.addEventListener('open', onOpen);
    menu?.addEventListener('close', onClose);
}

export default { init };