function animateValue(el: HTMLElement, start = 0, end = 100, duration = 800) {
    let startValue = start;
    let endValue = end;
    let animationDuration = duration;
    if (el.dataset.duration) {
        animationDuration = parseInt(el.dataset.duration, 10);
    }
    if (!el) {
        console.error('no el in function');
        return;
    }
    if (el.dataset.startValue) {
        startValue = parseFloat(el.dataset.startValue);
    }
    endValue = parseFloat(el.innerHTML.replace(/\s/g, ''));
    let startTimestamp: number | null = null;
    const step = (timestamp: number) => {
        if (!startTimestamp) {
            startTimestamp = timestamp;
        }
        const progress = Math.min((timestamp - startTimestamp) / animationDuration, 1);
        el.innerHTML = Math.floor(progress * (endValue - startValue) + startValue).toString();
        if (progress < 1) {
            window.requestAnimationFrame(step);
        }
    };
    window.requestAnimationFrame(step);
}

const revealItemsObserver = new IntersectionObserver(
    (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                revealItemsObserver.unobserve(entry.target);
                animateValue(entry.target as HTMLElement);
            }
        });
    },
    { rootMargin: '0% 0% 0% 0%', threshold: [0, 1] },
);

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll('.js-animate-counter')).forEach((el) => {
        revealItemsObserver.observe(el);
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll('.js-animate-counter')).forEach((el) => {
        revealItemsObserver.unobserve(el);
    });
}

const _module = { init, destroy };

export default _module;
