import axios from 'axios';

const loaderHtml = '<span class="app-loader app-loader--white-blue"></span>';
let filialWrapper: HTMLElement | null;
let filialPopup: HTMLElement | null;
const popupHeight = 120;
const pointWidth = 18;
let trigger: HTMLElement | null;
const header = document.querySelector<HTMLElement>('.js-header');
const headerHeight = header?.offsetHeight;

function getFilialHtml(action) {
    return new Promise((resolve) => {
        axios.get(action).then((res) => {
            if (res.data.status === 'success') {
                const layout = res.data.data.html.toString();
                resolve(layout);
            } else {
                resolve(res.data.message)
            }
        });
    });
}

function onOpen(instance) {
    trigger = instance.detail.triggerElement.classList.contains('js-filial-trigger')
        ? instance.detail.triggerElement
        : instance.detail.triggerElement.closest('.js-filial-trigger');
    if (!trigger) {
        return;
    }
    const { action } = trigger.dataset;
    const triggerRect = trigger.getBoundingClientRect();
    const triggerX = triggerRect.left;
    const triggerY = triggerRect.top;
    if (filialWrapper) {
        if (filialWrapper.offsetWidth + (triggerX + pointWidth + 3) > window.innerWidth) {
            filialWrapper.style.right = '0px';
            filialWrapper.style.left = 'unset';
        } else {
            filialWrapper.style.left = `${triggerX + pointWidth - 3}px`;
        }
        if (headerHeight && filialWrapper.offsetHeight + (triggerY - popupHeight + 1) < headerHeight) {
            filialWrapper.style.top = `${headerHeight + 20}px`;
        } else {
            filialWrapper.style.top = `${triggerY - popupHeight + 1}px`;
        }
    }
    trigger.classList.add('is-active');
    getFilialHtml(action).then((layout) => {
        if (filialWrapper && typeof layout === 'string') {
            filialWrapper.innerHTML = layout;
            setTimeout(() => {
                filialWrapper?.classList.add('is-show');
            }, 100);
        }
    });
}

function onClose() {
    trigger?.classList.remove('is-active');
    if (filialWrapper) {
        filialWrapper?.classList.remove('is-show');
        filialWrapper.innerHTML = loaderHtml;
    }
}

function init(container: HTMLElement | Document = document) {
    filialPopup = container.querySelector('[data-lit-popup="filial"]');
    filialWrapper = container.querySelector('.js-filial-popup-wrapper');
    filialPopup?.addEventListener('open', onOpen);
    filialPopup?.addEventListener('close-complete', onClose);
    const sliders = Array.from(container.querySelectorAll<HTMLElement>('.js-drag'));
    if (sliders && sliders.length > 0) {
        sliders.forEach((slider) => {
            let isDown = false;
            let startX = 0;
            let scrollLeft = 0;
            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 1.1;
                slider.scrollLeft = scrollLeft - walk;
            });
        });
    }
}

function destroy() { }

const _module = { init, destroy };

export default _module;
