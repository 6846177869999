/* eslint-disable no-restricted-globals */
import './webpack-imports';
import './sentry';
import './custom-elements';
import barba from '@barba/core';
import gsap from 'gsap';
import { applyPolyfills } from './polyfills';

import preloader from './components/preloader';

import { initBaseView } from './views/_base';
import lazyView from './views/_lazy';
import IndexPageView from './views/index';

import defaultTransition from './transitions/default';

import headerObserve from './inits/header-observe';
import burgerCondition from './inits/burger-condition';
import generateBurgerMenu from './inits/generate-burger-menu';
import fadeReveal from './inits/fade-reveal';

import { SplitText } from 'gsap/SplitText';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { CustomEase } from 'gsap/CustomEase';
import { Draggable } from 'gsap/Draggable';
import { InertiaPlugin } from 'gsap/InertiaPlugin';
import { SlowMo } from 'gsap/EasePack';
import ScrollTrigger from 'gsap/ScrollTrigger';
import inputFocus from './inits/input-focus';
import ajaxForms from './inits/ajax-forms';
import inputMasks from './inits/masks';
import searchInHeader from './inits/search-in-header';
import clearSearchBtn from './inits/clear-search-button';
import calculateScrollbarWidth from '../modules/calculate-scrollbar-width';
import initFileInput from './inits/file-input';

document.documentElement.classList.add('js-ready');
applyPolyfills().then(() => {
    gsap.config({ nullTargetWarn: false });
    gsap.registerPlugin(
        ScrollToPlugin,
        ScrollTrigger,
        DrawSVGPlugin,
        SplitText,
        CustomEase,
        Draggable,
        InertiaPlugin,
        SlowMo,
    );

    preloader.init();

    if (history.scrollRestoration) {
        history.scrollRestoration = 'manual';
    }

    headerObserve.init();
    generateBurgerMenu.init();
    searchInHeader.init();
    clearSearchBtn.init();
    calculateScrollbarWidth();

    const requestPopup = document.querySelector<HTMLElement>('[data-lit-popup="request"]');

    if (requestPopup) {
        initFileInput(requestPopup);
    }

    function setVisibilityState() {
        if (document.visibilityState === 'hidden') {
            document.documentElement.classList.add('is-document-hidden');
        } else {
            document.documentElement.classList.remove('is-document-hidden');
        }
    }

    document.addEventListener(
        'preloader.complete',
        () => {
            initBaseView();
            const footer = document.querySelector('.js-footer') as HTMLElement;

            burgerCondition.init();

            if (footer) {
                fadeReveal.init(footer);
            }

            const requestPopupEl = document.querySelector<HTMLElement>('app-lit-popup[data-lit-popup="request"]');

            if (requestPopupEl && requestPopupEl !== null) {
                ajaxForms.init(requestPopupEl);
                inputMasks.init(requestPopupEl);
                inputFocus.init(requestPopupEl);
            }

            barba.init({
                transitions: [defaultTransition],
                views: [
                    IndexPageView,
                    lazyView('about-page', () =>
                        import(/* webpackChunkName: "about-page" */ './views/about').then((m) => m.default),
                    ),
                    lazyView('text-page', () =>
                        import(/* webpackChunkName: "text-page" */ './views/text').then((m) => m.default),
                    ),
                    lazyView('white-text-page', () =>
                        import(/* webpackChunkName: "text-page" */ './views/white-text-page').then((m) => m.default),
                    ),
                    lazyView('list-page', () =>
                        import(/* webpackChunkName: "list-page" */ './views/list').then((m) => m.default),
                    ),
                    lazyView('contacts-page', () =>
                        import(/* webpackChunkName: "list-page" */ './views/contacts').then((m) => m.default),
                    ),
                    lazyView('disclosure-page', () =>
                        import(/* webpackChunkName: "list-page" */ './views/disclosure').then((m) => m.default),
                    ),
                    lazyView('search-page', () =>
                        import(/* webpackChunkName: "list-page" */ './views/search').then((m) => m.default),
                    ),
                    lazyView('error-page', () =>
                        import(/* webpackChunkName: "list-page" */ './views/error').then((m) => m.default),
                    ),
                ],
                prefetchIgnore: true,
                preventRunning: true,
                prevent: ({ el }) => el.tagName === 'A' && !!el.closest('#bx-panel'),
                timeout: 5000,
                // requestError: (trigger, action, url, response) => {
                //     if (response.status === 404) {
                //         barba.go(`${ROOT_PATH}404/`);
                //     }
                //     return false;
                // },
                debug: NODE_ENV === 'development',
            });

            setVisibilityState();
            document.addEventListener('visibilitychange', setVisibilityState);
        },
        {
            once: true,
        },
    );
});
// Always at the end
// module.hot?.accept();
