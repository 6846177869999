import barba, { IViewData } from '@barba/core';
import fadeReveal from '../inits/fade-reveal';
import { lazyload } from '../inits/lazyload';
import inputFocus from '../inits/input-focus';
import ScrollTrigger from 'gsap/ScrollTrigger';
import inputMasks from '../inits/masks';
import parallax from '../inits/parallax';
import type { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';
import lottie from '../inits/lottie';

export function initBaseView() {
    barba.hooks.enter((data) => {
        if (data && data.current && data.next && data.current.namespace && data.next.namespace) {
            document.body.classList.replace(data.current.namespace, data.next.namespace);
        }
        lazyload.update();
        const footer = document.querySelector<HTMLElement>('.js-footer');
        if (footer) {
            fadeReveal.init(footer);
        }
        inputFocus.init(data?.next.container);
        inputMasks.init(data?.next.container);
    });

    barba.hooks.afterEnter((data) => {
        parallax.init(data?.next.container);
        fadeReveal.init(data?.next.container);
    });

    barba.hooks.beforeEnter((data) => {
        lottie.init(data?.next.container);
    })

    barba.hooks.beforeLeave((data) => {
        const scrollTriggers = ScrollTrigger.getAll();

        if (scrollTriggers && scrollTriggers.length > 0) {
            scrollTriggers.forEach((st) => {
                st.kill();
            });
        }

        fadeReveal.destroy(data?.current.container);
        inputMasks.destroy(data?.current.container);
    });

    barba.hooks.leave(() => {
        const popups = Array.from(document.querySelectorAll<LitPopupElement>('app-lit-popup'));
        if (popups.length) {
            popups.forEach((popup) => {
                if (popup.instance.isOpen) {
                    popup.close();
                }
                popup.classList.remove('lit-popup--opened');
                popup.classList.remove('is-show');
            });
        }
    });

    barba.hooks.afterLeave((data) => {
        const footer = document.querySelector<HTMLElement>('.js-footer');
        if (footer) {
            const footerRevealed = Array.from(footer?.querySelectorAll('.js-reveal'));
            fadeReveal.destroy(footer);
            footerRevealed.forEach((item) => item.classList.remove('is-reveal'));
        }
        inputFocus.destroy(data?.current.container);
        lottie.destroy(data?.current.container);
    });
}
