import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

const curtain = document.querySelector('.js-page-curtain');
const fragment = document.createDocumentFragment();
const squares: HTMLElement[] = [];
const header = document.querySelector('.js-header');
const duration = .5;
const ease = 'power3.out';

if (curtain) {
    for (let i = 0; i < 2; i++) {
        const el = document.createElement('div');
        fragment.appendChild(el);
        squares.push(el);
    }

    curtain.appendChild(fragment);
}

export default {
    name: 'default page transition',

    enter() {
        window.scrollTo({ top: 0, behavior: 'auto' });
    },

    leave() {
        return new Promise((resolve) => {
            curtain?.classList.add('is-active');
            header?.classList.add('no-transition');
            gsap.set(squares[0], {transformOrigin: 'bottom'});
            gsap.timeline().to(squares[0], {
                duration,
                scaleY: 1,
                ease,
                onComplete: resolve,
            }).set(squares[1], {scaleY: 1, duration: 0})
        });
    },

    after() {
        const scrollTriggers = ScrollTrigger.getAll();
        if (scrollTriggers && scrollTriggers.length > 0) {
            scrollTriggers.forEach((st) => {
                st.refresh();
            });
        }

        requestAnimationFrame(() => {
            gsap.set(squares[0], {transformOrigin: 'top'});
            gsap.timeline().to(squares[0], {scaleY: 0})
                .to(squares[1], {
                    duration,
                    scaleY: 0,
                    ease,
                    onComplete: () => {
                        curtain?.classList.remove('is-active');
                        header?.classList.remove('no-transition');
                    },
                });
        });
    },
};
