let timer: NodeJS.Timeout;

type Controls = {
    video: HTMLElement | null;
    playpause: HTMLElement | null;
    controls: HTMLElement | null;
    loaded: boolean;
};

const map = new WeakMap<Element, IntersectionObserver>();

const callback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
        const video = entry.target as HTMLVideoElement;
        if (video) {
            if (entry.isIntersecting) {
                video.play();
            } else {
                video.pause();
            }
        }
        
    });
};
const commonRootMargin = '0px 0px 0px 0px';
const commonObserver = new IntersectionObserver(callback, { rootMargin: commonRootMargin, threshold: [0, 1] });

function init(container: HTMLElement | Document = document) {
    const wrappers = Array.from(container.querySelectorAll('.js-video-wrapper'));
    wrappers.forEach((wrapper) => {
        const controls: Controls = {
            video: wrapper.querySelector('.js-video'),
            playpause: wrapper.querySelector('.js-video-button'),
            controls: wrapper.querySelector('.js-video-controls'),
            loaded: false,
        };

        function showControls() {
            if (controls.controls) {
                controls.controls.classList.remove('is-hide');
            }
        }

        function hideControls() {
            if (controls.controls) {
                controls.controls.classList.add('is-hide');
            }
        }

        const { video } = controls;
        const playZone = controls.controls;
        const button = controls.playpause;

        function onPlayZoneClick() {
            if (video && video instanceof HTMLVideoElement) {
                if (video.paused) {
                    video.play();
                    button?.classList.add('is-play');
                    clearTimeout(timer);
                    timer = setTimeout(() => {
                        hideControls();
                    }, 1000);
                } else {
                    video.pause();
                    button?.classList.remove('is-play');
                }
            }
        }

        function onVideoClick() {
            clearTimeout(timer);
            showControls();
        }

        function onVideoEnded() {
            if (video && video instanceof HTMLVideoElement) {
                video.pause();
                button?.classList.remove('is-play');
                if (video.classList.contains('js-poster')) {
                    video.load();
                }
                clearTimeout(timer);
                showControls();
            }
        }

        function onPlay() {
            button?.classList.add('is-play');
            clearTimeout(timer);
            timer = setTimeout(() => {
                hideControls();
            }, 1000);
        }

        function onPause() {
            button?.classList.remove('is-play');
            clearTimeout(timer);
            showControls();
        }

        if (playZone) {
            playZone.addEventListener('click', onPlayZoneClick);
        }
        if (video && video instanceof HTMLVideoElement) {
            video.addEventListener('click', onVideoClick);
            video.addEventListener('ended', onVideoEnded);
            video.addEventListener('play', onPlay);
            video.addEventListener('pause', onPause);
        }
    });

    const bgVideos = Array.from(container.querySelectorAll<HTMLVideoElement>('.js-bg-video'));

    bgVideos.forEach((bgVideo) => {
        const observer = commonObserver;
        observer.observe(bgVideo);
        map.set(bgVideo, observer);

        if (bgVideo instanceof HTMLVideoElement) {
            if (bgVideo.readyState === 4) {
                bgVideo.play();
            } else {
                bgVideo.addEventListener('canplaythrough', () => {
                    bgVideo.play();
                });
            }
        }
    });
}

function destroy(container: HTMLElement | Document = document) {
    const bgVideos = Array.from(container.querySelectorAll<HTMLVideoElement>('.js-bg-video'));

    bgVideos.forEach((bgVideo) => {
        const observer = map.get(bgVideo);
        if (observer) {
            observer.unobserve(bgVideo);
            map.delete(bgVideo);
        }
    });
}

export default { init, destroy };
