import { fetchLodashThrottle } from '../dynamic-modules';

let header: HTMLElement | null;
let headerHeight = 0;
let throttle: any;
const body = document.querySelector<HTMLElement>('body');

function onScroll() {
    if (header && !body?.classList.contains('white-page')) {
        if (window.scrollY > headerHeight) {
            header.classList.add('header--white');
        } else {
            header.classList.remove('header--white');
        }
    }
}

export async function initThrottle() {
    throttle = await fetchLodashThrottle();
    const callback = throttle(onScroll, 50);
    window.addEventListener('scroll', callback);
}

function init(container = document) {
    initThrottle();
    header = container.querySelector<HTMLElement>('.js-header');
    headerHeight = header?.offsetHeight || 0;
}

function destroy() {
    window.removeEventListener('scroll', onScroll);
}

const _module = { init, destroy };

export default _module;