import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

function init(container: HTMLElement | Document = document) {
    const parallaxItems: HTMLElement[] = Array.from(container.querySelectorAll('[data-parallax]'));
    parallaxItems.forEach((item) => {
        let direction = 1;
        let duration = 0.01;
        if (item.dataset.direction) {
            direction = parseFloat(item.dataset.direction);
        }
        if (item.dataset.duration) {
            duration = parseFloat(item.dataset.duration);
        }
        if (window.matchMedia('(max-width: 1279px)').matches && item.dataset.desctop !== undefined) {
            return;
        }
        gsap.timeline({
            scrollTrigger: {
                trigger: item,
                start: 'bottom bottom',
                end: 'top top',
                scrub: 1,
            },
        }).to(item, { y: direction * ScrollTrigger.maxScroll(window) * duration }, 0);
    });
}

const _module = { init };

export default _module;
